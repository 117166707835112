<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";

var moment = require("moment");
moment.locale("fr");
/**
 * Blog-detail component
 */
export default {
  page: {
    title: "Blog Details",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcessVue,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      moment: moment,
      commentList: [],
      titre: "",
      progress: 0,
      process: false,
      article: "",
      isPublished: "",
      comment: 0,
      listLike: false,
      listFav: false,
      likesList: [],
      favList: [],
      image: "",
      categorie: "",
      tags: [],
      title: "Détails de l'article",
      items: [
        {
          text: "Articles",
          href: "/blog/list",
        },
        {
          text: "Details",
          active: true,
        },
      ],
    };
  },
  methods: {
    showListFav() {
      this.listFav = true;
    },
    showListLikes() {
      this.listFav = true;
    },
    getDateAndTimeFromDate(date) {
      const monthsInFrench = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];

      let year = date.getFullYear();
      let month = monthsInFrench[date.getMonth()];
      let day = date.getDate();

      return `${day} ${month} ${year}`;
    },

    confirm() {
      Swal.fire({
        title:
          this.isPublished == false
            ? "Etes-vous sûr de vouloir publier cet article ?"
            : "Etes-vous sûr de vouloir dépublier cet article?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.publishArticle();
          // Swal.fire("Deleted!", "Event has been deleted.", "success");
          // location.reload();
        }
      });
    },

    confirmPutInLoading() {
      Swal.fire({
        title: "Voulez vous vraiment faire passer cet article en attente",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.putInLoading();
          // Swal.fire("Deleted!", "Event has been deleted.", "success");
          // location.reload();
        }
      });
    },

    publishArticle() {
      // this.process = true;
      this.titre = "Publication de l'article";
      Api.put(
        `/actualite/rest/article/publish-article/${this.$route.params.id}`
      )
        .then(function (response) {
          Swal.fire(
            "Success!",
            response.data.content.isPublished == false
              ? "Article dépublié avec succès"
              : "Article publié avec succès",
            "success"
          );
          location.reload();
          // this.process = false;
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          Erreur.gestionErreur(error.message);
        });
    },

    putInLoading() {
      this.process = true;
      this.titre = "Article en attente";
      Api.patch(
        `/actualite/rest/article/update-status/${this.$route.params.id}`
      )
        .then(function () {
          // this.process = false;
          Swal.fire("Success!", "Article mis en attente", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    // this.process = true
    this.titre = "récupération de l'article";

    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      console.log(`Upload progress: ${this.progress}%`);
    };
    Api.get(`/actualite/rest/article/${this.$route.params.id}`, {
      onUploadProgress,
    })
      .then((res) => {
        console.log(res.data.content);
        this.process = false;
        this.article = res.data.content;
        this.isPublished = this.article.isPublished;
        this.commentList = this.article.comments;
        this.likesList = this.article.like_users;
        this.image = this.article.imagesMap.imagepaysage.url;
        this.favList = this.article.fav_users;
        this.categorie = this.article.categorie.name;
        this.tags = this.article.tags;
      })
      .catch((error) => {
        this.process = false;
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>

    <div style="display: flex">
      <div style="margin-right: 20px">
        <RouterLink
          :to="`/blog/updateArticle/${this.$route.params.id}`"
          type="submit"
          @click="createArticle"
          class="btn c2play-primary"
        >
          Modifier
        </RouterLink>
      </div>
      <div
        style="margin-right: 20px"
        v-if="article.status != 'PENDING' && article.status != 'PUBLISHED'"
      >
        <button
          type="submit"
          @click="confirmPutInLoading"
          class="btn c2play-primary"
        >
          Mettre en attente
        </button>
      </div>
      <div
        v-if="
          user.roles.includes('ROLE_ADMIN') &&
          (article.status == 'PENDING' || article.status == 'PUBLISHED')
        "
        style="margin-right: 20px"
      >
        <div v-if="article.isPublished == false">
          <button type="submit" @click="confirm" class="btn c2play-primary">
            Publier
          </button>
        </div>
        <div v-if="article.isPublished == true">
          <button type="submit" @click="confirm" class="btn c2play-primary">
            Dépublier
          </button>
        </div>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="pt-3">
              <div class="row justify-content-center">
                <div class="col-xl-12">
                  <div>
                    <div class="text-center">
                      <h4>{{ article.titre }}</h4>
                      <div class="row">
                        <div class="col-sm-6">
                          <p class="text-muted mb-4">
                            <i class="mdi mdi-calendar me-1"></i>
                            {{
                              getDateAndTimeFromDate(
                                new Date(article.creationDate)
                              )
                            }}
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p class="text-muted mb-4">
                            <i class="mdi mdi-account-box me-1"></i>
                            {{ article.autheur }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div class="text-center">
                      <div class="row">
                        <div class="col-sm-6">
                          <div>
                            <p class="text-muted mb-2 font-size-22">
                              <i class="mdi mdi-animation"></i> Catégorie
                            </p>
                            <h5 class="font-size-15">{{ categorie }}</h5>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div>
                            <p class="text-muted mb-2 font-size-22">
                              <i class="mdi mdi-checkbox-multiple-marked"></i>
                              Tags
                            </p>
                            <h5
                              class="font-size-15"
                              v-for="tag in tags"
                              :key="tag.id"
                            >
                              <span class="tags-input_tag">
                                {{ tag.name }}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-4" @click="showListLikes">
                          <div class="mt-4 mt-sm-0">
                            <p class="text-muted mb-2">
                              <i class="mdi mdi-thumb-up font-size-22"></i>
                            </p>
                            <h5 class="font-size-15">
                              {{ like }} Like<span v-if="like > 1">s</span>
                            </h5>
                          </div>
                        </div>

                        <div class="col-sm-4" @click="showListFav">
                          <div class="mt-4 mt-sm-0">
                            <p class="text-muted mb-2">
                              <i class="mdi mdi-bookmark font-size-22"></i>
                            </p>
                            <h5 class="font-size-15">
                              {{ fav }} Favori<span v-if="fav > 1">s</span>
                            </h5>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="mt-4 mt-sm-0">
                            <p class="text-muted mb-2">
                              <i class="mdi mdi-eye font-size-22"></i>
                            </p>
                            <h5 class="font-size-15">
                              {{ article.nb_vue }} Vue<span
                                v-if="article.nb_vue > 1"
                                >s</span
                              >
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div class="my-5">
                      <img
                        :src="image"
                        alt=""
                        class="img-thumbnail mx-auto d-block"
                        style="height: 400px; width: 80%; border-radius: 10px"
                      />
                    </div>

                    <hr />

                    <div class="mt-4">
                      <div
                        class="text-muted font-size-14"
                        style="overflow: hidden; text-align: justify"
                        v-html="article.content"
                      ></div>

                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
      <div class="col-lg-4">
        <div class="card" style="max-height: 1080px">
          <div class="card-body">
            <h4 class="mb-4">
              {{ $t("pages.overviewVideos.comment") }} {{ commentList.length }}
            </h4>

            <div
              class="d-flex mb-4"
              v-for="(commentaire, index) in commentList.slice().reverse()"
              :key="index"
            >
              <div class="avatar-sm me-4" style="min-width: 50px">
                <span
                  class="c2play-bg-secondary avatar-title rounded-circle bg-soft text-primary font-size-24"
                >
                  <!-- <img v-if="commentaire.user_photo" class="media-object rounded-circle avatar-xs" alt
                :src="commentaire.user_photo" /> -->
                  <img
                    class="media-object rounded-circle avatar-xs"
                    alt
                    src="@/assets/logos/images/profile.png"
                  />
                </span>
              </div>
              <div class="flex-grow-1">
                <h5 class="font-size-13 mb-1">
                  {{ commentaire.user.username }}
                </h5>
                <p class="text-muted mb-1">{{ commentaire.comment_content }}</p>
              </div>
              <div class="ms-3">
                {{ moment(commentaire.dateCration).fromNow() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <b-modal
      v-model="listLike"
      id="modal-ml"
      size="ml"
      title="Détails du programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card">
        <div class="comment-block card">
          <div class="card-body">
            <h4 class="card-title mb-4">
              {{ $t("pages.overviewVideos.likes") }} {{ this.likesList.length }}
            </h4>

            <div id="overview-chart" class="apex-charts" dir="ltr">
              <table
                class="table table-nowrap table-centered table-hover mb-0 align-middle"
              >
                <tbody>
                  <tr v-for="(like, index) in likesList" :key="index">
                    <td style="width: 45px">
                      <div class="avatar-sm">
                        <span
                          class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                        >
                          <img
                            class="media-object rounded-circle avatar-xs"
                            alt
                            src="@/assets/logos/images/profile.png"
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        {{ like }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="listFav"
      id="modal-ml"
      size="ml"
      title="Détails du programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card">
        <div class="comment-block card">
          <div class="card-body">
            <h4 class="card-title mb-4">
              {{ $t("pages.overviewVideos.fav") }} {{ favList.length }}
            </h4>
            <div class="table-responsive">
              <table
                class="table table-nowrap table-centered table-hover mb-0 align-middle"
              >
                <tbody>
                  <tr v-for="(fav, index) in favList" :key="index">
                    <td style="width: 45px">
                      <div class="avatar-sm">
                        <span
                          class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                        >
                          <img
                            class="media-object rounded-circle avatar-xs"
                            alt
                            src="@/assets/logos/images/profile.png"
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        {{ fav }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
